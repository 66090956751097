import axios from "../config";

class LoginService {
  axios;
  constructor() {
    this.axios = axios;
  }

  /**
   *
   * @param {UserAccount} userAccount
   *  {userName : string,password : string}
   *
   */
  loginAccount(userAccount) {
    let url = "/userAccounts/login";
    return axios.post(url, userAccount).then(request => request.data);
  }
}

const service = new LoginService();
export default service;
