<template>
  <v-container fill-height fluid>
    <v-row class="justify-center">
      <v-col cols="12" md="3">
        <v-row class="align-center login-full">
          <v-col class="pa-6">
            <div class="card-title">
              <div class="title-main"></div>
              <div class="title-sub">Airline Ticker Voucher</div>
            </div>
            <v-alert v-model="showError" dense type="error" class="mb-4">
              <div>{{ errorMessage }}</div>
            </v-alert>
            <v-form v-on:submit.prevent="accountLogin">
              <div class="headline">Account Login</div>
              <v-text-field
                label="UserName"
                v-model="userAccount.userName"
                hide-details
                class="mt-2"
              />
              <v-text-field
                label="Password"
                type="password"
                v-model="userAccount.password"
                hide-details
                class="mt-2 mb-6"
              />
              <v-btn type="submit" color="primary" class="mt-6" block @click="accountLogin">Login</v-btn>
            </v-form>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-dialog v-model="domainDialog" persistent max-width="300">
      <v-card>
        <v-card-title class="error white--text">
          <v-icon class="white--text">warning</v-icon>
          <span class="ml-2">Going Expire?</span>
        </v-card-title>
        <v-card-text class="pt-3 font-weight-bold error--text">{{domainDate}}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="closeDomainDialog" color="error" depressed>OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import loginService from "../service/LoginService";
import axios from "../config";

export default {
  mounted: function() {
    this.hideToolbar(true);
  },
  data() {
    return {
      userAccount: {
        userName: "",
        password: ""
      },
      nameRules: [v => !!v || "Name is required"],
      passwordRules: [v => !!v || "Password is required"],
      showError: false,
      errorMessage: "",
      domainDialog: false,
      domainDate: ""
    };
  },
  props: {
    hideToolbar: Function
  },
  methods: {
    accountLogin: function() {
      if (
        this.userAccount.userName.length != 0 &&
        this.userAccount.password.length != 0
      ) {
        loginService
          .loginAccount(this.userAccount)
          .then(data => {
            console.log(data);
            this.$store.commit("updateUserAccount", data);
            axios.defaults.headers.common[
              "Authorization"
            ] = ` Bearer ${data.password}`;
            this.hideToolbar(false);
            if(data.role == "ROLE_MSSTAFF"){
            this.$router.push("/bill");
            }else{
              this.$router.push("/");
            }
          })
          .catch(e => {
            if (e != undefined) {
              this.showError = true;
              if (e.response == undefined) {
                this.errorMessage = "Server Error";
              } else {
                if (e.response.data == undefined) {
                  this.errorMessage = "No Internet Connection.";
                } else {
                  this.errorMessage = e.response.data.message;
                }
              }
            }
          });
      } else {
        this.showError = true;
        this.errorMessage = "Fill Authorization";
      }
    },
    closeDomainDialog: function() {
      this.domainDialog = !this.domainDialog;
      this.$router.push("/").catch(() => {});
    }
  }
};
</script>

<style>
.login-full {
  height: 90vh;
}
.card-title {
  margin-bottom: 10px;
}
.title-main {
  font-size: 1.6rem;
  font-weight: 800;
  color: red;
  letter-spacing: 0.08rem;
}
.title-sub {
  text-align: left;
  color: #348df3;
}
</style>